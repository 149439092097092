import { Chain } from 'wagmi'
import { NetworkConfig } from './model'

export const noop = (): void => {
  return
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const mapToWagmiChainsModel = (networksConfig: NetworkConfig[]): Chain[] => {
  return networksConfig
    .filter((x) => x.rpc_url)
    .map((guruChain) => {
      const result = {
        id: guruChain.id,
        name: guruChain.description,
        network: guruChain.name,
        iconUrl: guruChain.logo_uri,
        nativeCurrency: {
          name: guruChain.native_token.name,
          symbol: guruChain.native_token.symbol,
          decimals: guruChain.native_token.decimals,
        },

        rpcUrls: {
          default: {
            http: [guruChain.rpc_url as string],
          },
          public: {
            http: [guruChain.rpc_url as string],
          },
        },
        blockExplorers: {
          default: {
            name: guruChain.block_explorer_display_name,
            url: guruChain.block_explorer_url,
          },
        },
        contracts:
          (guruChain.id === 1 && {
            ensRegistry: {
              address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' as `0x${string}`,
            },
          }) ||
          undefined,
        testnet: false,
      }
      return result
    })
}
